<template>
  <div
    ref="actuatorRef"
    class="flex flex-col divide-y divide-gray-200 rounded-lg shadow transition-all duration-300"
    :class="[
      isHighlighted
        ? 'scale-105 transform border-2 border-blue-500 bg-blue-100'
        : '',
      isInDefrostMode
        ? 'border-2 shadow-xl'
        : 'border border-gray-200 bg-white',
    ]"
    :style="isInDefrostMode ? { borderColor: '#FBBF24' } : {}"
  >
    <div class="flex items-center justify-between p-5">
      <h3 class="flex min-w-0 items-center font-semibold text-gray-900">
        <div class="truncate" :title="actuator.name">
          {{ actuator.name }}
        </div>
      </h3>
      <div class="flex items-center gap-3">
        <div
          v-if="hasAlerts"
          class="relative flex h-5 cursor-pointer items-center"
          @click.stop="toggleAlertsPopup"
        >
          <div
            class="flex h-5 items-center overflow-hidden rounded-full bg-white shadow-sm"
          >
            <template v-if="hasAlerts">
              <template v-if="alertCountsBySeverity[3] > 0">
                <!-- Icon container for severity 3 -->
                <div
                  class="flex h-5 w-4 items-center justify-start bg-red-500 pl-1.5"
                >
                  <font-awesome-icon
                    :icon="alertIcon"
                    class="h-2.5 w-2.5 text-white"
                  />
                </div>
                <div
                  class="flex h-5 min-w-[1.25rem] items-center justify-center bg-red-500 pr-1.5 text-xs font-medium text-white"
                >
                  {{ alertCountsBySeverity[3] }}
                </div>
                <div
                  v-if="alertCountsBySeverity[2] > 0"
                  class="-ml-1 flex h-5 min-w-[1.25rem] items-center justify-center bg-orange-500 pr-1 text-xs font-medium text-white"
                >
                  {{ alertCountsBySeverity[2] }}
                </div>
                <div
                  v-if="alertCountsBySeverity[1] > 0"
                  class="-ml-1 flex h-5 min-w-[1.25rem] items-center justify-center bg-yellow-400 pr-1 text-xs font-medium text-white"
                >
                  {{ alertCountsBySeverity[1] }}
                </div>
              </template>

              <template v-else-if="alertCountsBySeverity[2] > 0">
                <div
                  class="flex h-5 w-4 items-center justify-start bg-orange-500 pl-1.5"
                >
                  <font-awesome-icon
                    :icon="alertIcon"
                    class="h-2.5 w-2.5 text-white"
                  />
                </div>
                <div
                  class="flex h-5 min-w-[1.25rem] items-center justify-center bg-orange-500 pr-1.5 text-xs font-medium text-white"
                >
                  {{ alertCountsBySeverity[2] }}
                </div>
                <div
                  v-if="alertCountsBySeverity[1] > 0"
                  class="-ml-1 flex h-5 min-w-[1.25rem] items-center justify-center bg-yellow-400 pr-1 text-xs font-medium text-white"
                >
                  {{ alertCountsBySeverity[1] }}
                </div>
              </template>

              <template v-else-if="alertCountsBySeverity[1] > 0">
                <div
                  class="flex h-5 w-4 items-center justify-start bg-yellow-400 pl-1.5"
                >
                  <font-awesome-icon
                    :icon="alertIcon"
                    class="h-2.5 w-2.5 text-white"
                  />
                </div>
                <div
                  class="flex h-5 min-w-[1.25rem] items-center justify-center bg-yellow-400 pr-1.5 text-xs font-medium text-white"
                >
                  {{ alertCountsBySeverity[1] }}
                </div>
              </template>
            </template>
          </div>
        </div>
        <button type="button" class="flex" @click="openScheduleModal">
          <font-awesome-icon
            icon="calendar-days"
            class="h-6 w-6 text-gray-400 hover:text-gray-500"
          />
        </button>
        <button type="button" class="flex" @click="openLogsModal">
          <font-awesome-icon
            icon="list"
            class="h-6 w-6 text-gray-400 hover:text-gray-500"
          />
        </button>
        <button type="button" class="flex" @click="handleMaintenanceMode">
          <font-awesome-icon
            icon="wrench"
            class="h-6 w-6 text-gray-400 hover:text-gray-500"
            :class="{
              'text-yellow-400': isInDefrostMode || !actuator.automaticControl,
            }"
          />
        </button>
        <Switch
          :model-value="actuator.unitPower"
          :class="[
            actuator.unitPower ? 'bg-blue-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
          ]"
          :disabled="loading"
          @update:model-value="(val) => onUpdate(val, 'unitPower')"
        >
          <span
            :class="[
              actuator.unitPower ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            ]"
          />
        </Switch>
      </div>
    </div>

    <Transition
      enter-active-class="ease-out duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="showAlertsPopup"
        class="fixed inset-0 z-50 overflow-y-auto"
        @click.self="closeAlertsPopup"
      >
        <div class="flex min-h-screen items-center justify-center p-4">
          <!-- Overlay -->
          <Transition
            enter-active-class="ease-out duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              @click="closeAlertsPopup"
            ></div>
          </Transition>

          <!-- Modal panel -->
          <Transition
            enter-active-class="ease-out duration-300"
            enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white shadow-xl transition-all"
            >
              <ActuatorsAlerts
                :initial-actuator-name="actuator.name"
                :show-close-button="true"
                @close="closeAlertsPopup"
              />
            </div>
          </Transition>
        </div>
      </div>
    </Transition>

    <div class="flex h-64 divide-x">
      <div class="flex grow flex-col divide-y">
        <button
          v-if="actuator.setpointMode === 'manual'"
          class="flex-1 items-center justify-center hover:bg-gray-50 active:bg-gray-100"
          :disabled="loading"
          @click="increaseSetpointTempLow"
        >
          <font-awesome-icon icon="angle-up" class="h-6 w-6 text-gray-400" />
        </button>
        <div class="flex flex-1 flex-col items-center justify-center">
          <h6 class="text-sm text-gray-600">Inferior</h6>
          <h5 class="text-2xl text-gray-800">{{ setpointTempLow }}º</h5>
        </div>
        <button
          v-if="actuator.setpointMode === 'manual'"
          class="flex flex-1 items-center justify-center rounded-bl-lg hover:bg-gray-50 active:bg-gray-100"
          :disabled="loading"
          @click="decreaseSetpointTempLow"
        >
          <font-awesome-icon icon="angle-down" class="h-6 w-6 text-gray-400" />
        </button>
      </div>
      <div class="flex grow flex-col items-center justify-between divide-y">
        <SetpointPresetSelectMenu
          v-if="
            actuator.setpointMode === 'preset' ||
            actuator.setpointMode === 'manual'
          "
          :actuator-index="actuatorIndex"
          @loadingChanged="handleSetpointPresetSelectMenuLoadingChanged"
          @setpointModeChanged="handleSetpointModeChanged"
        />
        <div class="flex h-full w-full flex-col items-center justify-between">
          <div class="flex h-full flex-col items-center justify-center gap-8">
            <div class="relative">
              <div class="relative">
                <template v-if="isInDefrostMode">
                  <div class="relative">
                    <div
                      class="relative h-10 overflow-hidden"
                      style="clip-path: inset(0 0 43% 0)"
                    >
                      <div class="rotate-[30deg] transform">
                        <font-awesome-icon
                          icon="snowflake"
                          class="h-10 w-10 text-yellow-400"
                        />
                      </div>
                    </div>
                    <div
                      class="absolute -bottom-1 left-1/2 flex -translate-x-1/2 space-x-1"
                      style="
                        transform: translateY(-0.51rem) translateX(-1.32rem);
                      "
                    >
                      <font-awesome-icon
                        icon="droplet"
                        class="h-3 w-3 animate-water-drop-1 text-yellow-400"
                      />
                      <font-awesome-icon
                        icon="droplet"
                        class="h-3 w-3 animate-water-drop-2 text-yellow-400"
                      />
                      <font-awesome-icon
                        icon="droplet"
                        class="h-3 w-3 animate-water-drop-3 text-yellow-400"
                      />
                    </div>
                  </div>
                </template>
                <template
                  v-else-if="actuator.unitPower && actuator.unitMode === 'cool'"
                >
                  <font-awesome-icon
                    icon="snowflake"
                    :style="{
                      '--fa-beat-scale': '1.1',
                      '--fa-animation-duration': '2s',
                    }"
                    beat
                    class="h-10 w-10 text-blue-400"
                  />
                </template>
                <template
                  v-else-if="actuator.unitPower && actuator.unitMode === 'fan'"
                >
                  <font-awesome-icon
                    icon="fan"
                    :style="{ '--fa-animation-duration': '4s' }"
                    spin
                    class="h-10 w-10 text-emerald-400"
                  />
                </template>
                <template
                  v-else-if="actuator.unitPower && actuator.unitMode === 'heat'"
                >
                  <font-awesome-icon
                    icon="fire-flame-curved"
                    :style="{
                      '--fa-beat-scale': '1.2',
                      '--fa-animation-duration': '1s',
                    }"
                    beat
                    class="h-10 w-10 text-amber-500"
                  />
                </template>
                <template
                  v-else-if="actuator.unitPower && actuator.unitMode === 'auto'"
                >
                  <font-awesome-icon
                    icon="arrows-spin"
                    :style="{ '--fa-animation-duration': '4s' }"
                    spin
                    class="h-10 w-10 text-gray-400"
                  />
                </template>
              </div>
            </div>
            <div class="flex flex-col items-center space-y-1">
              <h5 :class="unitModeHuman.colorClass" class="text-lg font-medium">
                {{ unitModeHuman.text }}
              </h5>
              <h4
                v-if="actuator.ambientTemp !== null"
                class="text-5xl font-light text-gray-800"
              >
                {{ parseFloat(actuator.ambientTemp).toFixed(1) }}º
              </h4>
            </div>
          </div>
          <InfoToolTip
            v-if="
              actuator.controlCycleMode === 'eco' ||
              actuator.controlCycleMode === 'eco_2'
            "
            msg="Modo Eco: Optimiza el consumo de energía manteniendo el confort"
            icon="leaf"
            extra-class="h-5 w-5 text-emerald-400 cursor-pointer"
          />
          <div class="flex h-10 items-center justify-center gap-2">
            <font-awesome-icon
              icon="circle"
              class="h-2 w-2"
              :class="[status.colorClass]"
            />
            <h6 class="text-sm text-gray-600">{{ status.text }}</h6>
          </div>
        </div>
      </div>
      <div class="flex grow flex-col divide-y">
        <button
          v-if="actuator.setpointMode === 'manual'"
          class="flex-1 items-center justify-center hover:bg-gray-50 active:bg-gray-100"
          :disabled="loading"
          @click="increaseSetpointTempHigh"
        >
          <font-awesome-icon icon="angle-up" class="h-6 w-6 text-gray-400" />
        </button>
        <div class="flex flex-1 flex-col items-center justify-center">
          <h6 class="text-sm text-gray-600">Superior</h6>
          <h5 class="text-2xl text-gray-800">{{ setpointTempHigh }}º</h5>
        </div>
        <button
          v-if="actuator.setpointMode === 'manual'"
          class="flex flex-1 items-center justify-center rounded-br-lg hover:bg-gray-50 active:bg-gray-100"
          :disabled="loading"
          @click="decreaseSetpointTempHigh"
        >
          <font-awesome-icon icon="angle-down" class="h-6 w-6 text-gray-400" />
        </button>
      </div>
    </div>
    <div class="relative">
      <div
        :class="{ 'animate-bounce-gentle': isInDefrostMode }"
        class="relative"
      ></div>
    </div>
  </div>
  <ActuatorSettingsModal
    :is-open="isActuatorSettingsModalOpen"
    :actuator-id="actuator.id"
    :is-maintenance-active="!actuator.automaticControl"
    :defrost-automatic-mode="actuator.defrostAutomaticMode"
    :defrost-mode="actuator.defrostMode"
    :defrost-start-time="actuator.defrostStartTime"
    @update:is-open="isActuatorSettingsModalOpen = $event"
    @close="closeActuatorSettingsModal"
    @activate="activateMaintenanceMode"
    @deactivate="deactivateMaintenanceMode"
    @defrost-automatic-mode-toggle="toggleDefrostAutomaticMode"
    @defrost-mode-toggle="toggleDefrostMode"
  />
</template>

<script>
import { Switch } from "@headlessui/vue";
import SetpointPresetSelectMenu from "./SetpointPresetSelectMenu.vue";
import ActuatorSettingsModal from "./ActuatorSettingsModal.vue";
import InfoToolTip from "../cards/InfoToolTip.vue";
import ActuatorsAlerts from "./ActuatorsAlerts.vue";

const MIN_SETPOINT_TEMP = 0;
const MAX_SETPOINT_TEMP = 40;
const DEBOUNCE_TIME_MS = 1000;

export default {
  name: "ActuatorsGridItem",
  components: {
    SetpointPresetSelectMenu,
    ActuatorSettingsModal,
    Switch,
    InfoToolTip,
    ActuatorsAlerts,
  },
  props: {
    actuatorIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      userUpdatePending: false,
      isActuatorSettingsModalOpen: false,
      setpointPresetSelectMenuLoading: false,
      debounceTimer: null,
      showAlertsPopup: false,
      countdownInterval: null,
    };
  },
  computed: {
    actuator() {
      const actuator = this.$store.state.venueActuators[this.actuatorIndex];
      return actuator;
    },
    isInDefrostMode() {
      return this.actuator.defrostMode;
    },
    setpointPreset() {
      return this.$store.state.setpointPresets[
        this.actuator.currentSetpointPresetUuid
      ];
    },
    setpointTempLow() {
      if (this.actuator.setpointMode === "manual") {
        return this.actuator.setpointTempLow;
      } else if (this.actuator.setpointMode === "preset") {
        return this.setpointPreset.setpointTempLow;
      }
      return null;
    },
    setpointTempHigh() {
      if (this.actuator.setpointMode === "manual") {
        return this.actuator.setpointTempHigh;
      } else if (this.actuator.setpointMode === "preset") {
        return this.setpointPreset.setpointTempHigh;
      }
      return null;
    },
    unitModeHuman() {
      if (this.actuator.unitPower) {
        if (this.actuator.defrostMode) {
          return { text: "Descongelando", colorClass: "text-yellow-400" };
        }
        switch (this.actuator.unitMode) {
          case "cool":
            return { text: "Enfriando", colorClass: "text-blue-500" };
          case "fan":
            return { text: "Ventilando", colorClass: "text-emerald-500" };
          case "heat":
            return { text: "Calentando", colorClass: "text-amber-500" };
          case "auto":
            return { text: "Automático", colorClass: "text-gray-500" };
          default:
            return { text: "", colorClass: "text-gray-500" };
        }
      }
      return { text: "Apagado", colorClass: "text-gray-500" };
    },
    status() {
      if (!this.actuator.automaticControl) {
        return { text: "Mantención", colorClass: "text-yellow-400" };
      }
      if (this.loading || this.setpointPresetSelectMenuLoading) {
        return { text: "Actualizando", colorClass: "text-amber-400" };
      }
      if (this.actuator.online) {
        return { text: "Conectado", colorClass: "text-emerald-400" };
      }
      return { text: "Desconectado", colorClass: "text-red-400" };
    },
    isHighlighted() {
      if (this.$store.state.highlightedActuatorId === this.actuator.id) {
        this.$nextTick(() => {
          const element = this.$el;
          const elementRect = element.getBoundingClientRect();
          const absoluteElementTop = elementRect.top + window.pageYOffset;
          const middle = absoluteElementTop - window.innerHeight / 2;
          window.scrollTo({ top: middle, behavior: "smooth" });
        });
      }
      return this.$store.state.highlightedActuatorId === this.actuator.id;
    },
    alertCountsBySeverity() {
      let counts = { 1: 0, 2: 0, 3: 0 };

      // Get alerts from store that match this actuator's id
      const actuatorAlerts = this.$store.state.venueActuatorsAlerts.filter(
        (alert) => alert.uuid === this.actuator.id && !alert.correct
      );

      actuatorAlerts.forEach((alert) => {
        let severity;

        if (alert.severity !== undefined) {
          severity = alert.severity;
        } else if (alert.alerts && alert.alerts.length > 0) {
          const mostRecentAlert = alert.alerts[alert.alerts.length - 1];
          severity = mostRecentAlert.severity;
        }
        if (!severity) {
          severity = this.getDefaultSeverity(alert.alertType);
        }

        severity = Math.max(1, Math.min(3, Number(severity)));

        counts[severity] = (counts[severity] || 0) + 1;
      });

      return counts;
    },
    hasAlerts() {
      const actuatorAlerts = this.$store.state.venueActuatorsAlerts.filter(
        (alert) => alert.uuid === this.actuator.id && !alert.correct
      );
      return actuatorAlerts.length > 0;
    },
    totalAlertsCount() {
      const { 1: s1, 2: s2, 3: s3 } = this.alertCountsBySeverity;
      return s1 + s2 + s3;
    },
    alertIcon() {
      return "exclamation-circle";
    },
  },
  watch: {
    userUpdatePending: {
      handler(newValue) {
        if (newValue) {
          clearTimeout(this.debounceTimer);
          this.debounceTimer = setTimeout(() => {
            if (this.userUpdatePending) {
              this.dispatchUpdateActuator();
            }
          }, DEBOUNCE_TIME_MS);
        }
      },
    },
    actuator: {
      deep: true,
      immediate: true,
      handler: function (actuator) {
        if (this.userUpdatePending) {
          clearTimeout(this.debounceTimer);
          this.debounceTimer = setTimeout(
            this.dispatchUpdateActuator,
            DEBOUNCE_TIME_MS
          );
        }

        if (this.countdownInterval) {
          clearInterval(this.countdownInterval);
          this.countdownInterval = null;
        }

        if (actuator?.defrostMode && actuator?.defrostStartTime) {
          const startTime = new Date(actuator.defrostStartTime).getTime();
          const now = Date.now();
          const elapsed = Math.floor((now - startTime) / 1000);

          if (elapsed < 900) {
            // 15 minutes = 900 seconds
            const updateCountdown = () => {
              const currentTime = Date.now();
              const currentElapsed = Math.floor(
                (currentTime - startTime) / 1000
              );
              const remaining = 900 - currentElapsed; // 15 minutes = 900 seconds

              if (remaining > 0) {
                this.$store.commit("setDefrostCache", {
                  actuatorId: actuator.id,
                  startTime: actuator.defrostStartTime,
                });
              } else {
                clearInterval(this.countdownInterval);
                this.countdownInterval = null;

                this.$store.dispatch("updateActuator", {
                  id: actuator.id,
                  data: {
                    defrost_mode: false,
                    defrost_start_time: null,
                  },
                });
              }
            };

            updateCountdown();
            this.countdownInterval = setInterval(updateCountdown, 1000);
          }
        }
      },
    },
  },
  beforeUnmount() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  },
  methods: {
    async dispatchUpdateActuator() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        this.clearUserUpdatePending();
        let data = {
          unitPower: this.actuator.unitPower,
        };

        if (this.actuator.setpointMode === "manual") {
          data.manualSetpointTempLow = parseFloat(
            this.actuator.setpointTempLow
          );
          data.manualSetpointTempHigh = parseFloat(
            this.actuator.setpointTempHigh
          );
        }

        await this.$store.dispatch("updateActuator", {
          id: this.actuator.id,
          data: data,
        });
      } finally {
        this.loading = false;
      }
    },
    setUserUpdatePending() {
      this.userUpdatePending = true;
      this.$store.commit("setActuatorUserUpdatePending");
    },
    clearUserUpdatePending() {
      this.userUpdatePending = false;
      this.$store.commit("clearActuatorUserUpdatePending");
    },
    increaseSetpointTempLow() {
      if (this.actuator.setpointMode !== "manual") {
        return;
      }

      // Parse both values as floats for comparison
      const currentTemp = parseFloat(this.actuator.setpointTempLow);
      const maxTemp = parseFloat(this.actuator.setpointTempHigh);

      if (isNaN(currentTemp) || isNaN(maxTemp)) {
        return;
      }

      if (currentTemp < maxTemp) {
        const newValue = (currentTemp + 0.2).toFixed(1);
        this.onUpdate(newValue, "setpointTempLow");
      }
    },
    decreaseSetpointTempLow() {
      if (this.actuator.setpointMode !== "manual") {
        return;
      }

      const currentTemp = parseFloat(this.actuator.setpointTempLow);

      if (isNaN(currentTemp)) {
        return;
      }

      if (currentTemp > MIN_SETPOINT_TEMP) {
        const newValue = (currentTemp - 0.2).toFixed(1);
        this.onUpdate(newValue, "setpointTempLow");
      }
    },
    increaseSetpointTempHigh() {
      if (this.actuator.setpointMode !== "manual") {
        return;
      }

      const currentTemp = parseFloat(this.actuator.setpointTempHigh);

      if (isNaN(currentTemp)) {
        return;
      }

      if (currentTemp < MAX_SETPOINT_TEMP) {
        const newValue = (currentTemp + 0.2).toFixed(1);
        this.onUpdate(newValue, "setpointTempHigh");
      }
    },
    decreaseSetpointTempHigh() {
      if (this.actuator.setpointMode !== "manual") {
        return;
      }

      // Parse both values as floats for comparison
      const currentTemp = parseFloat(this.actuator.setpointTempHigh);
      const minTemp = parseFloat(this.actuator.setpointTempLow);

      if (currentTemp > minTemp) {
        const newValue = (currentTemp - 0.2).toFixed(1);
        this.onUpdate(newValue, "setpointTempHigh");
      }
    },
    openLogsModal() {
      this.$store.commit("setSelectedActuatorId", this.actuator.id);
      this.$store.commit("openActuatorLogsModal");
      this.showAlertsPopup = false;
    },
    openScheduleModal() {
      this.$store.commit("setSelectedActuatorId", this.actuator.id);
      this.$store.commit("openActuatorScheduleModal");
    },
    handleSetpointPresetSelectMenuLoadingChanged(newValue) {
      this.setpointPresetSelectMenuLoading = newValue;
    },
    handleSetpointModeChanged(newValue) {
      this.onUpdate(newValue, "setpointMode");
    },
    openActuatorSettingsModal() {
      this.isActuatorSettingsModalOpen = true;
    },
    closeActuatorSettingsModal() {
      this.isActuatorSettingsModalOpen = false;
    },
    activateMaintenanceMode({ actuatorId, duration }) {
      this.loading = true;
      this.clearUserUpdatePending();
      const data = { delay: duration };
      this.$store.dispatch("activateMaintenanceMode", {
        id: actuatorId,
        data: data,
      });
      this.loading = false;
    },
    handleMaintenanceMode() {
      this.openActuatorSettingsModal();
    },
    deactivateMaintenanceMode(actuatorId) {
      this.loading = true;
      this.clearUserUpdatePending();
      this.$store
        .dispatch("deactivateMaintenanceMode", actuatorId)
        .finally(() => {
          this.loading = false;
        });
    },
    toggleAlertsPopup() {
      this.showAlertsPopup = !this.showAlertsPopup;
    },
    closeAlertsPopup() {
      "Alerts popup closed for actuator:", this.actuator.name;
      this.showAlertsPopup = false;
    },
    getNormalUnitMode() {
      switch (this.actuator.unitMode) {
        case "cool":
          return { text: "Enfriando", colorClass: "text-blue-500" };
        case "fan":
          return { text: "Ventilando", colorClass: "text-emerald-500" };
        case "heat":
          return { text: "Calentando", colorClass: "text-amber-500" };
        default:
          return { text: "", colorClass: "text-gray-500" };
      }
    },
    async toggleDefrostAutomaticMode() {
      this.loading = true;
      try {
        const payload = {
          defrostAutomaticMode: !this.actuator.defrostAutomaticMode,
          defrostMode: this.actuator.defrostMode,
          defrostStartTime: this.actuator.defrostStartTime,
        };
        await this.$store.dispatch("updateActuator", {
          id: this.actuator.id,
          data: payload,
        });
      } finally {
        this.loading = false;
      }
    },
    async toggleDefrostMode(payload) {
      this.loading = true;
      try {
        await this.$store.dispatch("toggleDefrostMode", {
          actuatorId: this.actuator.id,
          defrostMode: payload.defrostMode,
          defrostStartTime: payload.defrostStartTime,
        });
      } finally {
        this.loading = false;
      }
    },
    onUpdate(value, prop) {
      let currentValue = this.actuator[prop];
      let newValue = value;

      // Handle numeric conversions for temperature props
      if (prop.includes("Temp")) {
        currentValue = parseFloat(currentValue);
        newValue = parseFloat(value);
      }

      if (newValue !== currentValue) {
        this.setUserUpdatePending();
        this.actuator[prop] = prop.includes("Temp")
          ? newValue.toFixed(1)
          : value;
      }
    },
  },
};
</script>
