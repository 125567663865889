module.exports = {
  content: ["./src/**/*.{html,vue,js,ts,jsx,tsx}"],
  theme: {
    extend: {
      height: {
        fit: "fit-content",
      },
      screens: {
        print: { raw: "print" },
        screen: { raw: "screen" },
      },
      colors: {
        footer: "#FAF8F8",
        flairblack: "#1D1030",
        flairgreen: "#46E8B1",
        hovergreen: "#37b48a",
        lightgreen: "#4EF2E6",
        flairpurple: "#6332e8",
        flairblue: "#4A75F1",
        hoverblue: "#3e61c6",
        flairppm: "#001E6C",
        flairhum: "#FFAA4C",
        flairtem: "#4A75F1",
        // HEATMAP
        heatblue: "#3661A2",
        heatlightblue: "#6A99BC",
        heatyellow: "#FACD7D",
        heatlightred: "#F36241",
        heatred: "#C81C1D",
        graphyellow: "#FEFEEB",
        // INDICATORS
        productivity: "#6574cd",
        absenteeism: "#f66d9b",
        cognition: "#f6993f",
      },
      animation: {
        "spin-slow": "spin 3s linear infinite",
        "pulse-slow": "pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite",
        "spin-slow-alt": "spin 8s linear infinite",
        "bounce-gentle": "bounce 3s infinite",
        "water-drop-1": "waterDropFall 3s linear infinite",
        "water-drop-2": "waterDropFall 3s linear infinite 1s",
        "water-drop-3": "waterDropFall 3s linear infinite 2s",
      },
      keyframes: {
        waterDropFall: {
          "0%": {
            transform: "translateY(0)",
            opacity: "0",
          },
          "10%": {
            opacity: "1",
          },
          "80%": {
            opacity: "1",
          },
          "100%": {
            transform: "translateY(20px)",
            opacity: "0",
          },
        },
      },
      spacing: {
        "1/24": "2.888888%",
        "1/7": "14.285714%",
        "1/6": "16.666667%",
        "3/2": "150%",
      },
    },
  },
  plugins: [
    require("@tailwindcss/forms")({
      strategy: "class",
    }),
  ],
};
