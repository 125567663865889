<template>
  <section class="relative flex-grow pb-16">
    <div class="flex flex-col gap-4">
      <!-- DatePicker Section -->
      <div class="flex flex-col justify-around pt-2 md:flex-row md:pt-5">
        <div class="w-full text-center">
          <date-picker :disabled="loading" class="inline-flex justify-center" />
        </div>
      </div>
      <div class="hidden w-full border-t border-gray-300 lg:block"></div>
      <heat-map :loading="loading" />
    </div>
    <div class="absolute inset-x-0 bottom-0 bg-gray-100 py-4 text-center">
      <span class="text-gray-600"
        >Actualizado a las {{ lastUpdatedSensors }}</span
      >
    </div>
  </section>
</template>

<script>
import DatePicker from "../dashboard/DatePicker.vue";
import HeatMap from "./HeatMap.vue";
import _ from "lodash";
import { listenerMixin } from "../../mixins";

export default {
  name: "Dashboard",
  components: {
    DatePicker,
    HeatMap,
  },
  mixins: [listenerMixin],

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    sensor() {
      return this.$store.state.currentSensor;
    },
    data() {
      const rawData = this.$store.getters.sensorQueryDataArray(this.sensor);
      return _.filter(
        rawData,
        ({ values }) =>
          values.co2 != null &&
          values.temperature != null &&
          values.humidity != null
      );
    },
    selectedDate() {
      return this.$store.state.selectedDate;
    },
    lastUpdatedSensors() {
      return this.$store.state.lastUpdatedSensors;
    },
  },

  watch: {
    sensor: {
      deep: true,
      handler: "changeSensor",
    },
    selectedDate: {
      deep: true,
      handler: "changeQueryData",
    },
  },

  async created() {
    this.loading = true;
    await this.$store.dispatch("loadVenueGrid");
    await this.$store.dispatch("loadVenueInfo");
    this.loading = false;
  },

  methods: {
    async changeSensor() {
      this.loading = true;
      if (this.sensor) {
        await this.$store.dispatch("loadSensorConfiguration", {
          sensor: this.sensor,
        });
      }
      this.loading = false;
    },
    async changeQueryData() {
      this.loading = true;
      if (this.sensor) {
        await this.$store.dispatch("loadSensorConfiguration", {
          sensor: this.sensor,
        });
        await this.$store.dispatch("loadSensorQueryData", {
          sensor: this.sensor,
        });
      }
      await this.$store.dispatch("loadVenueGrid");
      this.$store.dispatch("loadVenueInfo");
      this.loading = false;
    },
  },
};
</script>
