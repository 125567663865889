<template>
  <div>
    <modal
      v-if="isModalVisible"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      @close-modal="closeModal"
      @in-modal="inModal"
      @toggle-config="changeModal"
    >
      <template #body>
        <div v-if="!loading" class="flex h-full w-full flex-col">
          <div class="mb-4 inline-flex justify-center">
            <p class="mr-2 text-3xl font-medium">
              {{ selectedSensor?.name }}
            </p>
          </div>
          <div class="flex h-full w-full flex-grow justify-center">
            <div class="flex h-full w-full flex-col">
              <div class="mt-3 flex justify-center">
                <date-picker
                  :disabled="loading"
                  class="z-10 flex justify-center"
                />
              </div>
              <measurement-charts
                :brand="selectedSensor?.brand"
                class="h-full w-full flex-grow"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex h-full min-h-[33rem] flex-col items-center justify-center"
        >
          <font-awesome-icon
            icon="spinner"
            class="h-12 w-12 animate-spin text-gray-600"
          />
        </div>
      </template>
    </modal>
    <modal
      v-if="isConfigModalVisible"
      modal-class="modal-config"
      @close-modal="closeConfigModal"
      @in-modal="inModal"
    >
      <template #header> Head </template>
      <template #body> Body </template>
      <template #footer> Footer </template>
    </modal>

    <ul
      role="list"
      class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2"
    >
      <li
        v-for="sensor in em300Sensors"
        :key="sensor.id"
        class="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white shadow"
      >
        <div class="flex items-center justify-between p-5">
          <h3 class="font-semibold text-gray-900">{{ sensor.name }}</h3>
        </div>
        <div class="flex h-64">
          <div class="flex grow flex-col items-center justify-between">
            <div
              class="flex h-full w-full flex-col items-center justify-between"
            >
              <div class="flex h-full flex-col items-center justify-center">
                <font-awesome-icon
                  v-if="!sensor.isAlerted"
                  icon="snowflake"
                  class="mb-4 h-14 w-14 text-blue-400"
                />
                <font-awesome-icon
                  v-else
                  icon="triangle-exclamation"
                  class="mb-4 h-14 w-14 text-red-500"
                />
                <div class="flex flex-col items-center">
                  <p class="mb-1 text-base text-gray-500">Temperatura actual</p>
                  <h4 class="text-6xl font-light text-gray-800">
                    {{ sensor.values?.temperature }}º
                  </h4>
                  <p
                    class="mt-4 max-w-[200px] text-center text-sm text-gray-400"
                  >
                    Rango de funcionamiento: entre
                    {{ getOperatingRange(sensor) }}
                  </p>
                </div>
              </div>
              <div class="flex h-10 items-center justify-center gap-2">
                <span
                  :class="[
                    isSensorOnline(sensor) ? 'bg-green-400' : 'bg-red-400',
                    'h-2 w-2 rounded-full',
                  ]"
                ></span>
                <span class="text-sm text-gray-600">
                  {{ isSensorOnline(sensor) ? "Online" : "Offline" }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            class="relative flex w-full items-center justify-center py-4 text-sm font-semibold text-gray-900 hover:bg-gray-50"
            @click="selectSensor(sensor)"
          >
            <span class="absolute left-4">
              <font-awesome-icon icon="arrow-right" class="invisible" />
            </span>
            <span>Gráfico</span>
            <span class="absolute right-4">
              <font-awesome-icon icon="arrow-right" class="text-gray-400" />
            </span>
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import DatePicker from "../dashboard/DatePicker.vue";
import Modal from "../cards/Modal.vue";
import MeasurementCharts from "./MeasurementsCharts.vue";
import { listenerMixin } from "../../mixins";
import moment from "moment";

export default {
  name: "HeatMap",
  components: {
    DatePicker,
    Modal,
    MeasurementCharts,
  },
  mixins: [listenerMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSensor: null,
      isModalVisible: false,
      isConfigModalVisible: false,
      inside: false,
    };
  },
  computed: {
    isToday() {
      return this.$store.state.selectedDate.isSame(moment(), "day");
    },
    isSuperuser() {
      return this.$store.state.user.isSuperuser;
    },
    venueGrid() {
      return this.$store.state.venueGrid;
    },
    filledSensors() {
      if (!this.venueGrid) return [];
      return (
        this.venueGrid?.sensors?.filter((sensor) => sensor.brand === "em300") ??
        []
      );
    },
    em300Sensors() {
      return this.filledSensors;
    },
  },
  methods: {
    selectSensor(sensor) {
      this.selectedSensor = sensor;
      this.isModalVisible = true;
      this.$store.dispatch("selectSensor", { id: sensor.pk });
    },
    inModal() {
      this.inside = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.selectedChart = 0;
      this.inside = false;
    },
    closeConfigModal() {
      this.isConfigModalVisible = false;
      this.inside = false;
    },
    changeModal() {
      this.isModalVisible = false;
      this.isConfigModalVisible = true;
    },
    isSensorOnline(sensor) {
      if (!sensor.values?.timestamp) return false;
      const lastUpdate = moment(sensor.values.timestamp);
      const oneHourAgo = moment().subtract(1, "hour");
      return lastUpdate.isAfter(oneHourAgo);
    },
    getOperatingRange(sensor) {
      return sensor?.coldChamberType === "negative"
        ? "-20° y -15° aproximadamente"
        : "0° y 10° aproximadamente";
    },
  },
};
</script>
