<template>
  <TransitionRoot as="template" :show="localIsOpen">
    <Dialog as="div" class="relative z-10" @close="closeModal">
      <TransitionChild
        as="div"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center sm:p-0"
          @click.self="closeModal"
        >
          <TransitionChild
            as="div"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative w-full max-w-3xl transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all"
            >
              <button
                type="button"
                class="absolute right-4 top-4 text-gray-400 hover:text-gray-500"
                @click="closeModal"
              >
                <span class="sr-only">Close</span>
                <font-awesome-icon
                  icon="times"
                  class="h-6 w-6"
                  aria-hidden="true"
                />
              </button>
              <!-- MOBILE TAB NAV -->
              <div class="bg-white shadow-sm md:hidden">
                <div class="flex items-center justify-around p-4">
                  <button
                    v-for="item in navItems"
                    :key="item.id"
                    class="flex flex-col items-center rounded-lg px-6 py-3 transition-colors duration-200"
                    :class="
                      activeTab === item.id
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:bg-gray-100'
                    "
                    @click="setActiveTab(item.id)"
                  >
                    <font-awesome-icon
                      :icon="getIcon(item.iconName)"
                      class="mb-1 h-6 w-6"
                    />
                    <span class="text-sm font-medium">{{ item.label }}</span>
                  </button>
                </div>
              </div>

              <!-- DESKTOP LAYOUT -->
              <div class="flex flex-col md:flex-row">
                <!-- DESKTOP SIDEBAR -->
                <div
                  class="hidden w-60 flex-shrink-0 flex-col border-r border-gray-200 bg-gray-50 md:flex"
                >
                  <div class="flex items-center gap-2 p-4">
                    <div
                      class="flex h-8 w-8 items-center justify-center rounded-lg bg-blue-500 text-white"
                    >
                      <font-awesome-icon icon="wrench" class="h-5 w-5" />
                    </div>
                    <span class="text-xl font-medium">Ajustes</span>
                  </div>
                  <div class="space-y-2 px-2">
                    <button
                      v-for="item in navItems"
                      :key="item.id"
                      class="flex w-full cursor-pointer items-center gap-3 rounded-lg p-3 text-left transition-colors duration-200"
                      :class="
                        activeTab === item.id
                          ? 'bg-blue-50 text-blue-600'
                          : 'text-gray-600 hover:bg-gray-100'
                      "
                      @click="setActiveTab(item.id)"
                    >
                      <font-awesome-icon
                        :icon="getIcon(item.iconName)"
                        class="h-5 w-5"
                      />
                      <span
                        :class="
                          activeTab === item.id
                            ? 'text-blue-600'
                            : 'text-gray-800'
                        "
                      >
                        {{ item.label }}
                      </span>
                    </button>
                  </div>
                </div>

                <!-- MAIN CONTENT -->
                <div class="flex-1 p-6">
                  <!-- MAINTENANCE MODE -->
                  <div v-if="activeTab === 'maintenance'">
                    <div v-if="isMaintenanceActive">
                      <h2
                        class="text-center text-xl font-semibold leading-6 text-gray-900 sm:text-left"
                      >
                        Modo mantención activo
                      </h2>
                      <p
                        class="mt-2 text-center text-sm text-gray-500 sm:text-left"
                      >
                        El modo mantención está actualmente activo. ¿Desea
                        desactivarlo?
                      </p>
                      <div class="mt-5 sm:mt-6">
                        <button
                          type="button"
                          class="inline-flex w-full justify-center rounded-md bg-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline"
                          @click="deactivateMaintenanceMode"
                        >
                          Desactivar
                        </button>
                      </div>
                    </div>
                    <!-- If not active -->
                    <div v-else>
                      <h2
                        class="text-center text-xl font-semibold leading-6 text-gray-900 sm:text-left"
                      >
                        Duración modo mantención
                      </h2>
                      <p
                        class="mt-2 text-center text-sm text-gray-500 sm:text-left"
                      >
                        El modo mantención desactivará el control automático por
                        el tiempo especificado.
                      </p>

                      <div class="mt-5 flex justify-center space-x-6 sm:mt-6">
                        <div>
                          <label
                            for="hours"
                            class="mb-1 block text-center text-sm font-medium text-gray-700"
                          >
                            Horas
                          </label>
                          <input
                            id="hours"
                            v-model.number="hours"
                            type="number"
                            min="0"
                            max="12"
                            class="block w-24 rounded-md border-gray-300 px-4 py-3 text-center text-2xl shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            @input="validateHours"
                          />
                        </div>
                        <div>
                          <label
                            for="minutes"
                            class="mb-1 block text-center text-sm font-medium text-gray-700"
                          >
                            Minutos
                          </label>
                          <input
                            id="minutes"
                            v-model.number="minutes"
                            type="number"
                            min="0"
                            step="5"
                            :disabled="hours === 12"
                            class="block w-24 rounded-md border-gray-300 px-4 py-3 text-center text-2xl shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            :class="{
                              'bg-gray-100 text-gray-500': hours === 12,
                            }"
                            @input="validateMinutes"
                          />
                        </div>
                      </div>
                      <div class="mt-2 text-center">
                        <p class="text-sm text-gray-500">
                          Mínimo 30 minutos, máximo 12 horas.
                        </p>
                      </div>
                      <div class="mt-5 sm:mt-6">
                        <button
                          :disabled="!isValidDuration"
                          :class="[
                            'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline',
                            isValidDuration
                              ? 'bg-blue-500 hover:bg-blue-400 focus-visible:outline-blue-600'
                              : 'cursor-not-allowed bg-gray-400 opacity-50',
                          ]"
                          @click="activateMaintenanceMode"
                        >
                          Activar
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- DEFROST MODE -->
                  <div v-else-if="activeTab === 'defrost'">
                    <h2
                      class="text-center text-xl font-semibold leading-6 text-gray-900 sm:text-left"
                    >
                      Control de Descongelado
                    </h2>
                    <p
                      class="mt-2 text-center text-sm text-gray-500 sm:text-left"
                    >
                      Configure las opciones de descongelado automático del
                      equipo
                    </p>

                    <div class="mt-6 space-y-4">
                      <div
                        class="flex items-center justify-between rounded-lg bg-gray-50 p-4"
                      >
                        <div>
                          <p class="mb-1 font-medium text-gray-900">
                            Habilitar Descongelado Automático
                          </p>
                          <p class="text-sm text-gray-500">
                            Ejecuta ciclos de 15 min de descongelado<br />
                            automáticamente cada 90 min en modo enfriando.
                          </p>
                        </div>
                        <Switch
                          v-model="localDefrostAutomaticMode"
                          class="relative inline-flex h-6 w-11 items-center rounded-full"
                          :class="
                            localDefrostAutomaticMode
                              ? 'bg-blue-600'
                              : 'bg-gray-200'
                          "
                          @update:modelValue="onDefrostAutomaticModeToggled"
                        >
                          <span class="sr-only"
                            >Habilitar descongelado automático</span
                          >
                          <span
                            class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                            :class="
                              localDefrostAutomaticMode
                                ? 'translate-x-6'
                                : 'translate-x-1'
                            "
                          />
                        </Switch>
                      </div>

                      <div
                        class="flex items-center justify-between rounded-lg bg-gray-50 p-4"
                      >
                        <div>
                          <p class="mb-1 font-medium text-gray-900">
                            Activar Descongelado Manual
                          </p>
                          <p class="text-sm text-gray-500">
                            <span v-if="defrostTimeLeft">
                              Tiempo restante: {{ defrostTimeLeft }}
                            </span>
                            <span v-else>Inicia un ciclo de 15 minutos</span>
                          </p>
                        </div>
                        <Switch
                          :model-value="localDefrostMode"
                          class="relative inline-flex h-6 w-11 items-center rounded-full"
                          :class="
                            localDefrostMode ? 'bg-blue-600' : 'bg-gray-200'
                          "
                          @update:modelValue="onDefrostModeToggled"
                        >
                          <span class="sr-only"
                            >Activar descongelado manual</span
                          >
                          <span
                            class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                            :class="
                              defrostMode ? 'translate-x-6' : 'translate-x-1'
                            "
                          />
                        </Switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, computed, watch, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
  Switch,
} from "@headlessui/vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faClock,
  faSnowflake,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

library.add(faClock, faSnowflake, faBars, faTimes);

export default {
  name: "ActuatorSettingsModal",
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    Switch,
    FontAwesomeIcon,
  },
  props: {
    isOpen: Boolean,
    actuatorId: { type: String, required: true },
    isMaintenanceActive: { type: Boolean, required: true },
    defrostAutomaticMode: { type: Boolean, required: true },
    defrostMode: { type: Boolean, required: true, default: false },
    defrostStartTime: { type: String, default: null },
  },
  emits: [
    "close",
    "update:isOpen",
    "activate",
    "deactivate",
    "defrost-automatic-mode-toggle",
    "defrost-mode-toggle",
  ],
  setup(props, { emit }) {
    const localIsOpen = ref(false);
    const store = useStore();

    /* ---------------- TABS ---------------- */
    const activeTab = ref("maintenance");
    const navItems = [
      { id: "maintenance", iconName: "clock", label: "Modo Mantención" },
      { id: "defrost", iconName: "snowflake", label: "Descongelado" },
    ];
    function setActiveTab(tab) {
      activeTab.value = tab;
    }
    function getIcon(name) {
      if (name === "clock") return faClock;
      if (name === "snowflake") return faSnowflake;
      return faBars;
    }

    /* --------- MAINTENANCE --------- */
    const hours = ref(0);
    const minutes = ref(30);

    function validateMinutes() {
      if (minutes.value > 59) {
        minutes.value = 0;
        hours.value = (hours.value + 1) % 13;
      } else if (minutes.value < 0) {
        minutes.value = 59;
        hours.value = hours.value > 0 ? hours.value - 1 : 12;
      }
    }

    function validateHours() {
      if (hours.value > 12) {
        hours.value = 0;
      } else if (hours.value < 0) {
        hours.value = 12;
      }
      if (hours.value === 12) {
        minutes.value = 0;
      }
    }

    const isValidDuration = computed(() => {
      const totalMin = hours.value * 60 + minutes.value;
      return totalMin >= 30 && totalMin <= 720; // 30 min ~ 12 hours
    });

    function activateMaintenanceMode() {
      if (!isValidDuration.value) return;
      const durationInSeconds = (hours.value * 60 + minutes.value) * 60;
      emit("activate", {
        actuatorId: props.actuatorId,
        duration: durationInSeconds,
      });
      closeModal();
    }

    function deactivateMaintenanceMode() {
      emit("deactivate", props.actuatorId);
      closeModal();
    }

    /* ---------- DEFROST ---------- */
    const localDefrostAutomaticMode = ref(props.defrostAutomaticMode);
    const localDefrostMode = ref(props.defrostMode);

    const currentTimeLeft = ref(null);
    const defrostInterval = ref(null);

    const defrostTimeLeft = computed(() => {
      return currentTimeLeft.value;
    });

    watch(
      () => props.isOpen,
      (newVal) => {
        localIsOpen.value = newVal;
      },
      { immediate: true }
    );

    watch(
      () => props.defrostAutomaticMode,
      (newVal) => {
        localDefrostAutomaticMode.value = newVal;
      }
    );

    watch(
      () => props.defrostMode,
      (newVal) => {
        localDefrostMode.value = newVal;

        if (newVal && props.defrostStartTime) {
          if (defrostInterval.value) clearInterval(defrostInterval.value);

          const updateTimeLeft = () => {
            const status = store.getters.getDefrostStatus(props.actuatorId);
            if (!status.isActive) {
              clearInterval(defrostInterval.value);
              currentTimeLeft.value = null;
              return;
            }

            const minutes = Math.floor(status.timeLeft / 60000);
            const seconds = Math.floor((status.timeLeft % 60000) / 1000);
            currentTimeLeft.value = `${minutes}m ${seconds
              .toString()
              .padStart(2, "0")}s`;
          };

          updateTimeLeft();
          defrostInterval.value = setInterval(updateTimeLeft, 1000);
        } else {
          if (defrostInterval.value) {
            clearInterval(defrostInterval.value);
            currentTimeLeft.value = null;
          }
        }
      },
      { immediate: true }
    );

    watch(
      () => props.defrostStartTime,
      (newVal) => {
        if (newVal && props.defrostMode) {
          const status = store.getters.getDefrostStatus(props.actuatorId);
          if (status.isActive) {
            const minutes = Math.floor(status.timeLeft / 60000);
            const seconds = Math.floor((status.timeLeft % 60000) / 1000);
            currentTimeLeft.value = `${minutes}m ${seconds
              .toString()
              .padStart(2, "0")}s`;
          }
        }
      }
    );

    onBeforeUnmount(() => {
      if (defrostInterval.value) {
        clearInterval(defrostInterval.value);
      }
    });

    function onDefrostAutomaticModeToggled(newVal) {
      localDefrostAutomaticMode.value = newVal;

      emit("defrost-automatic-mode-toggle", {
        defrostAutomaticMode: newVal,
      });
    }

    async function onDefrostModeToggled(newVal) {
      // Immediately update visual state
      const prevState = localDefrostMode.value;
      localDefrostMode.value = newVal;

      try {
        const startTime = newVal ? new Date().toISOString() : null;

        store.commit("setDefrostCache", {
          actuatorId: props.actuatorId,
          startTime,
        });

        if (newVal) {
          const timer = setInterval(() => {
            const status = store.getters.getDefrostStatus(props.actuatorId);
            if (!status.isActive) {
              clearInterval(timer);
              localDefrostMode.value = false;
            }
            store.commit("setDefrostCache", {
              actuatorId: props.actuatorId,
              startTime,
            });
          }, 1000);
        }

        // Move emit after visual update but before API calls
        emit("defrost-mode-toggle", {
          defrostMode: newVal,
          defrostStartTime: startTime,
        });
      } catch (error) {
        // Revert the switch state if something fails
        localDefrostMode.value = prevState;
        console.error("Error toggling defrost mode:", error);
      }
    }

    function closeModal() {
      localIsOpen.value = false;
      emit("update:isOpen", false);
    }

    return {
      activeTab,
      navItems,
      setActiveTab,
      getIcon,
      faBars,

      hours,
      minutes,
      validateMinutes,
      validateHours,
      isValidDuration,
      activateMaintenanceMode,
      deactivateMaintenanceMode,

      localDefrostAutomaticMode,
      localDefrostMode,
      defrostTimeLeft,

      onDefrostAutomaticModeToggled,
      onDefrostModeToggled,

      localIsOpen,
      closeModal,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 767px) {
  .md\:flex {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .md\:hidden {
    display: none !important;
  }
}
</style>
