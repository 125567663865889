<template>
  <div class="relative flex-grow bg-gray-100 pb-16">
    <div class="mx-auto flex h-full max-w-7xl flex-col gap-6 p-6 lg:flex-row">
      <div class="order-1 flex w-full flex-1 flex-col gap-4 lg:order-2">
        <Dashboard />
      </div>

      <div class="order-2 flex flex-col lg:order-1 lg:w-96">
        <aside
          class="flex w-full flex-col overflow-y-scroll rounded-lg bg-white shadow"
        >
          <Alerts />
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from "./Dashboard.vue";
import Alerts from "./Alerts.vue";

export default {
  components: {
    Dashboard,
    Alerts,
  },
  data() {
    return {
      interval: null,
    };
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>
